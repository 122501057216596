<template>
  <div>
    <v-app-bar
      :clipped-left="clipped"
      fixed
      app
      color="#1D2B36"
      dense
      dark
      elevation="1"
    >
      <v-app-bar-nav-icon @click.stop="drawer = !drawer" />
      <span v-if="getAuth.empresa">{{ getAuth.empresa.nome }}</span>
      <v-spacer />

      <Menu />
    </v-app-bar>
    <v-navigation-drawer v-model="drawer" :clipped="clipped" fixed app>
      <template v-slot:prepend>
        <v-list-item two-line>
          <v-list-item-avatar>
            <v-icon>{{ mdiAccount }}</v-icon>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title>{{ getAuth.email }}</v-list-item-title>
            <v-list-item-subtitle class="caption">{{
              getAuth.roles.toString()
            }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </template>

      <v-divider></v-divider>
      <v-list nav dense v-if="this.getAuth">
        <v-list-item-group color="primary">
          <v-list-item
            v-for="(item, i) in items"
            :key="i"
            :to="item.to"
            router
            exact
            v-show="item.mostrar"
          >
            <v-list-item-action>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title v-text="item.title" />
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>

      <template v-slot:append>
        <div class="pa-2 d-flex justify-center">
          <h6>B4SIS</h6>
        </div>
      </template>
    </v-navigation-drawer>
  </div>
</template>

<script>
import Menu from "./Menu";
import {
  mdiAccount,
  mdiViewDashboardOutline,
  mdiAccountTieOutline,
  mdiAccountHardHat,
  mdiCalculatorVariantOutline,
  mdiStickerCheckOutline,
  mdiApplicationCogOutline,
  mdiAccountOutline,
  mdiFileExcelOutline,
} from "@mdi/js";
import { mapGetters } from "vuex";
export default {
  name: "head",
  components: {
    Menu,
  },
  props: {
    clipped: Boolean,
  },
  data() {
    return {
      drawer: true,
      items: [],
      mdiAccount,
    };
  },
  computed: {
    ...mapGetters(["getAuth"]),
    isAdmin() {
      return (
        this.getAuth.roles &&
        this.getAuth.roles.length > 0 &&
        this.getAuth.roles.includes("ADMIN")
      );
    },
    isCliente() {
      return (
        this.getAuth.roles &&
        this.getAuth.roles.length > 0 &&
        this.getAuth.roles.includes("CLIENTE")
      );
    },
  },
  methods: {},
  beforeDestroy() {
    this.items = [];
  },
  mounted() {
    this.items = [
      {
        icon: mdiCalculatorVariantOutline,
        title: "Contas a Pagar",
        to: { name: "Contas", params: { id: this.$route.params.id } },
        mostrar: this.isAdmin,
      },
      {
        icon: mdiCalculatorVariantOutline,
        title: "Contas a Receber",
        to: { name: "ContasReceber", params: { id: this.$route.params.id } },
        mostrar: this.isAdmin,
      },
      {
        icon: mdiViewDashboardOutline,
        title: "Projetos",
        to: { name: "Projeto", params: { id: this.$route.params.id } },
        mostrar: this.isAdmin,
      },
      {
        icon: mdiViewDashboardOutline,
        title: "Projetos Finalizados",
        to: {
          name: "ProjetoFinalizado",
          params: { id: this.$route.params.id },
        },
        mostrar: this.isAdmin,
      },
      {
        icon: mdiAccountTieOutline,
        title: "Clientes",
        to: { name: "Clientes", params: { id: this.$route.params.id } },
        mostrar: this.isAdmin,
      },
      {
        icon: mdiAccountHardHat,
        title: "Fornecedores",
        to: { name: "Fornecedores", params: { id: this.$route.params.id } },
        mostrar: this.isAdmin,
      },
      {
        icon: mdiApplicationCogOutline,
        title: "Empresa",
        to: { name: "Empresa", params: { id: this.$route.params.id } },
        mostrar: this.isAdmin,
      },
      {
        icon: mdiAccountOutline,
        title: "Usuários",
        to: { name: "Usuario", params: { id: this.$route.params.id } },
        mostrar: this.isAdmin,
      },
      {
        icon: mdiFileExcelOutline,
        title: "Relatório Contas",
        to: {
          name: "RelatorioContasPeriodo",
          params: { id: this.$route.params.id },
        },
        mostrar: this.isAdmin,
      },
      {
        icon: mdiStickerCheckOutline,
        title: "Aprovar Orcamento",
        to: { name: "AprovarOrcamento", params: { id: this.$route.params.id } },
        mostrar: this.isCliente,
      },

      // {
      //   icon: mdiAccountHardHat,
      //   title: "Contas a pagar",
      //   to: { name: "ContasPagar", params: { id: this.$route.params.id } },
      //   mostrar: this.isAdmin,
      // },
    ];
  },
};
</script>

<style></style>
