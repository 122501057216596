<template>
  <v-menu
    :close-on-content-click="true"
    :nudge-width="100"
    offset-y
    transition="slide-y-transition"
  >
    <template v-slot:activator="{ on, attrs }">
      <div v-bind="attrs" v-on="on">
        <v-btn icon>
          <v-icon>mdi-dots-vertical</v-icon>
        </v-btn>
      </div>
    </template>
    <v-card>
      <v-list v-if="conta.items.length > 0">
        <v-list-item
          link
          v-for="item in conta.items"
          :key="item.title"
          @click="to(item.name)"
        >
          <v-list-item-icon>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title v-text="item.title"></v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <v-card-actions>
        <v-btn color="primary darken-1" text link block @click="logout">
          SAIR
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-menu>
</template>
<script>
import { mdiAccountCircleOutline, mdiLock } from "@mdi/js";
import { mapActions, mapGetters } from "vuex";
export default {
  data() {
    return {
      conta: {
        title: "Conta",
        url: "/account",
        items: [
          {
            title: "Alterar Senha",
            icon: mdiLock,
            name: "alterar-senha",
          },
        ],
      },
      mdiAccountCircleOutline,
    };
  },
  computed: {
    ...mapGetters(["getAuth"]),
  },
  methods: {
    ...mapActions(["logout"]),
    to(name) {
      this.$emit("drawer");

      this.$router.push({
        name: name,
        params: { id: this.$route.params.id },
      });
    },
  },
};
</script>
<style scoped>
.text {
  color: white;
}
</style>
