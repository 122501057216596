<template>
  <v-app dark>
    <Header :clipped="clipped" v-if="getAuth" />
    <v-main>
      <notifications :duration="3000" />
      <v-container fluid class="ma-0 pa-0">
        <transition name="slide" mode="out-in">
          <router-view> </router-view>
        </transition>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import { mapGetters } from "vuex";
import Header from "./components/Header";
export default {
  components: {
    Header,
  },
  data() {
    return {
      clipped: true,
      drawer: true,
    };
  },
  computed: {
    ...mapGetters(["getAuth"]),
  },
};
</script>
<style>
.slide-leave-active,
.slide-enter-active {
  transition-duration: 0.3s;
  transition-property: opacity;
  transition-timing-function: ease;
}
.slide-enter {
  opacity: 0;
}
.slide-leave-to {
  opacity: 0;
}

.square.v-btn:not(.v-btn--round).v-size--default {
  min-width: 36px;
  padding: 0;
}

input {
  text-transform: uppercase;
}
textarea {
  text-transform: uppercase;
}
</style>
